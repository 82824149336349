import React from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';

interface OffsetInputProps {
  offset: number;
  negateOffset: () => void;
  addOffset: () => void;
}

const OffsetInput: React.FC<OffsetInputProps> = ({ offset, negateOffset, addOffset }) => (
  <>
    <p><MinusIcon data-testid="minus" onClick={negateOffset} className={classNames({ 'opacity-30': offset === 15 })} /></p>
    <NumberFormat readOnly displayType="input" prefix="€" value={offset} className="border-dashed border-secondary-darker border-b-2 text-center font-semibold text-5xl w-1/2" />
    <p><PlusIcon data-testid="plus" onClick={addOffset} /></p>
  </>
);

export default OffsetInput;
