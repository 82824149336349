import classNames from 'classnames';
import React from 'react';

interface BackButtonProps {
  light?: boolean;
  onClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ light, onClick }) => (
  <button type="button" onClick={onClick} className={classNames({ 'text-white': light })}>
    <svg width="24" height="24" viewBox="0 0 24 24" className="h-5 w-5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4L7 12L15 20" stroke="currentColor" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </button>
);

export default BackButton;
