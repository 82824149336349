import {
  useMemo, useState,
} from 'react';

export enum EmbedProfile {
  crypto2Cash = 'crypto2cash',
  test = 'test',
}

interface UseEmbedResponse {
  isEmbedded: boolean,
  embedProfileId: EmbedProfile | null,
  isCloseVisible: boolean,
}

const useEmbed = (): UseEmbedResponse => {
  const [embedProfileId, setEmbedProfileId] = useState<EmbedProfile | null>(null);
  const [isEmbedded, setIsEmbedded] = useState(false);

  const getValidEmbed = (embedAttempt: string | null): EmbedProfile | null => (
    Object.values(EmbedProfile).includes(embedAttempt as EmbedProfile)
      ? (embedAttempt as EmbedProfile)
      : null
  );

  const embedId = 'embed';
  const queryParamEmbed = getValidEmbed(new URLSearchParams(window.location.search).get(embedId));
  const sessionStorageEmbed = getValidEmbed(sessionStorage.getItem(embedId));
  const isCloseVisible = isEmbedded && window.location.pathname !== '/crypto2cash/close';

  useMemo(() => {
    if (queryParamEmbed) {
      setIsEmbedded(true);
      setEmbedProfileId(queryParamEmbed);
      sessionStorage.setItem(embedId, queryParamEmbed);
    }

    if (sessionStorageEmbed) {
      setIsEmbedded(true);
      setEmbedProfileId(sessionStorageEmbed);
    }
  }, [queryParamEmbed, sessionStorageEmbed, embedId]);

  return {
    isEmbedded,
    embedProfileId,
    isCloseVisible,
  };
};

export default useEmbed;
