import React from 'react';

interface ModalProps {
  isVisible?: boolean;
}

const Modal: React.FC<ModalProps> = ({ isVisible, children }) => {
  if (!isVisible) return null;
  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-60 z-50 md:opacity-0" />
      <div className="absolute top-1/3 md:top-0 md:bottom-0 left-0 right-0 mx-auto w-11/12 md:w-full shadow rounded-3xl md:rounded-none bg-white p-10 z-50 flex flex-col justify-center">
        {children}
      </div>
    </>
  );
};

export default Modal;
