import React from 'react';
import calculateCarbonFootprint from '../../utils/calculateCarbonFootprint';

interface CarbonFootprintProps {
  products: Array<number>;
}

const CarbonFootprint: React.FC<CarbonFootprintProps> = ({ products }) => {
  const { btc, tonne } = calculateCarbonFootprint(products);
  return (
    <>
      {`You will offset ${btc} BTC transactions
      that equals approx. ${tonne} tonnes of CO2`}
    </>
  );
};

export default CarbonFootprint;
