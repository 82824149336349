/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Swipe1 } from '../../assets/swiper/onboarding/swiper-img-1.svg';
import { ReactComponent as Swipe2 } from '../../assets/swiper/onboarding/swiper-img-2.svg';
import { ReactComponent as Swipe3 } from '../../assets/swiper/onboarding/swiper-img-3.svg';
import Background from '../../components/Background';
import Button from '../../components/Button';
import Carousel from '../../components/Carousel';
import ContentArea from '../../components/ContentArea';
import { LogoWhite } from '../../components/Logo';
import Slide from './Slide';

const totalSlides = 3;

const OffsetPage: React.FC = () => (
  <Background type="green">
    <ContentArea light>
      <div className="flex-grow mx-auto px-6 mb-5 w-4/12 md:mb-11 md:w-2/12">
        <LogoWhite />
      </div>
      <div className="flex-grow">
        <Carousel
          slidesPerView={1.2}
          wrapperClassName="offset-page"
          slideClassName="flex h-auto"
          slides={[
            <Slide Image={Swipe1} title="Did you know?" current={1} total={totalSlides}>
              A single onchain BTC transaction results in more
              carbon emissions than taking a 2-hour flight.
            </Slide>,
            <Slide Image={Swipe2} title="Global footprint" current={2} total={totalSlides}>
              In 2021 the Bitcoin network will consume 1% of global electricity.
            </Slide>,
            <Slide Image={Swipe3} title="Fossil fuels" current={3} total={totalSlides}>
              More than 60 % of BTC network hash rate depends on fossil fuels.
              The annual carbon footprint of the Bitcoin network is more than
              30 million tonnes of CO2.
            </Slide>,
          ]}
        />
      </div>
      <Link className="w-full px-8 md:px-0 md:w-5/12 mx-auto" to="offset">
        <Button>Continue</Button>
      </Link>
    </ContentArea>
  </Background>
);

export default OffsetPage;
