import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const defaultIsOpen = true;

export const EmbedContext = React.createContext({
  isOpen: defaultIsOpen,
  close: () => {},
});

export const EmbedContextProvider: React.FC = ({ children }) => {
  const [isOpen] = useState(defaultIsOpen);
  const history = useHistory();

  const close = () => {
    history.push('/crypto2cash/close');
  };

  const values = {
    isOpen,
    close,
  };

  return (
    <EmbedContext.Provider value={values}>
      {children}
    </EmbedContext.Provider>
  );
};
