import classNames from 'classnames';
import React from 'react';
import { summary as images } from '../../images';
import TonneNumber from './TonneNumber';

const getTonneImage = (value: number) => (value === 1 ? images.tonne : images.tonnes);

interface TonneDisplayProps {
  className?: string;
  value: number;
}

const TonneDisplay: React.FC<TonneDisplayProps> = ({ className, value }) => {
  if (!value) return null;
  return (
    <div className={classNames('table', className)}>
      <TonneNumber value={value} />
      <div className="table-cell pl-3">
        <img src={getTonneImage(value)} alt="tonnes" />
      </div>
    </div>
  );
};

export default TonneDisplay;
