/* eslint-disable react/no-array-index-key */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import { summary as images } from '../../images';

const numberMap = [
  images.zero,
  images.one,
  images.two,
  images.three,
  images.four,
  images.five,
  images.six,
  images.seven,
  images.eight,
  images.nine,
];

const splitNumber = (number: number) => {
  const output = [];
  const sNumber = number.toString();

  for (let i = 0, len = sNumber.length; i < len; i += 1) {
    output.push(+sNumber.charAt(i));
  }

  return output;
};

const generateNumberImages = (value: number) => splitNumber(value).map(
  (num, index) => {
    const isDot = Number.isNaN(num);
    const src = isDot ? images.dot : numberMap[num];
    return (
      <div className="table-cell" key={`${num}-${index}`}>
        <img key={`${num}-${index}`} src={src} alt={num.toString()} />
      </div>
    );
  },
);

interface TonneNumberProps {
  value: number;
}

const TonneNumber: React.FC<TonneNumberProps> = ({ value }) => (
  <>
    {generateNumberImages(value)}
  </>
);

export default TonneNumber;
