const inputOptions = {
  classes: {
    base: 'stripe-field border w-full rounded-md shadow-sm bg-white p-5 mt-5',
    invalid: 'invalid-field',
  },
  style: {
    base: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '16px',
      '::placeholder': {
        color: '#a9a9a9',
      },
      lineHeight: '24px',
    },
    invalid: {
      color: '#ef4444',
    },
  },
};

export default inputOptions;
