import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';
import React, { MouseEvent, useState } from 'react';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CardCompleted from '../../types/CardCompleted.type';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import CardErrors from '../../types/CardErrors.type';

interface CardSectionProps {
  disabled?: boolean;
  onSubmit: (e: MouseEvent, shouldPay?: boolean) => Promise<void>;
  setCardCompleted: React.Dispatch<React.SetStateAction<CardCompleted>>
  setCardType: React.Dispatch<React.SetStateAction<StripeCardNumberElementChangeEvent['brand']>>
}

const CardSection: React.FC<CardSectionProps> = ({
  disabled, onSubmit, setCardCompleted, setCardType,
}) => {
  const [errors, setErrors] = useState<CardErrors>({});

  return (
    <>
      <div className="px-6 mt-4">
        <p className="text-3xl text-white font-bold">
          Add card information
        </p>
        <p className="text-white font-medium opacity-75">
          Fill in the fields below or use camera phone to scan your credit or debit card
        </p>
      </div>
      <div className="flex flex-col bg-white flex-grow -mb-5 md:w-full rounded-t-2xl mt-12 py-10">
        <div className="flex-grow w-full md:w-9/12 mx-auto px-6">
          <Card
            errors={errors}
            setErrors={setErrors}
            setCardCompleted={setCardCompleted}
            setCardType={setCardType}
          />
          <p className="text-xs mt-5">
            <LockIcon className="inline-block mr-2 align-bottom" />
            No credit card details are stored or seen
          </p>
        </div>
        <div className="w-full px-8 md:px-0 md:w-5/12 mx-auto">
          <Button
            disabled={disabled}
            color="bg-accent-darker"
            onClick={async (e: React.MouseEvent): Promise<void> => { await onSubmit(e); }}
          >
            Add card
          </Button>
        </div>
      </div>
    </>
  );
};

export default CardSection;
