interface FootPrintArray {
  btc: number;
  tonne: number;
}

const calculateCarbonFootprint = (products: Array<number>): FootPrintArray => {
  if (products[0]) {
    return {
      btc: 1,
      tonne: 0.5,
    };
  }

  const multiplier = products[1];
  return {
    btc: 2 * multiplier,
    tonne: 1 * multiplier,
  };
};

export default calculateCarbonFootprint;
