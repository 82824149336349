import classNames from 'classnames';
import React from 'react';

interface ButtonGroupProps {
  className: string;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children, className }) => (
  <div className={classNames('flex justify-center rounded-lg text-lg', className)} role="group">
    {children}
  </div>
);

interface ButtonGroupButtonProps {
  onClick: () => void;
  className?: string;
  isActive?: boolean;
}

export const ButtonGroupButton: React.FC<ButtonGroupButtonProps> = (
  {
    children,
    className,
    onClick,
    isActive,
  },
) => (
  <button
    onClick={onClick}
    type="button"
    className={
        classNames('hover:bg-accent-darker hover:text-white border border-accent-darker px-4 py-2 mx-0 outline-none focus:shadow-outline',
          { 'bg-accent-darker text-white': isActive, 'bg-white text-accent-darker': !isActive },
          className)
    }
  >
    {children}
  </button>
);

export default ButtonGroup;
