import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Logo } from '../../components/Logo';
import Background from '../../components/Background';
import ContentArea from '../../components/ContentArea';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';

const schema = yup.object().shape({
  accepted: yup.bool()
    .oneOf([true]),
});

const RedirectingPage = (): ReactElement => {
  const { register, formState: { isDirty, isValid } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const { push } = useHistory();
  return (
    <Background type="cloud">
      <ContentArea>
        <div className="flex flex-col flex-grow mx-auto px-6 pb-5 md:w-9/12">
          <div className="flex flex-grow justify-center mb-5">
            <Logo />
          </div>
          <div className="flex-grow">
            <p className="font-bold text-center text-xl">
              Please note, you are now being redirected to a
              third party service provided by ImpactScope.
            </p>
            <p className="text-center mt-3 text-secondary">
              Crypto2Cash is in no manner a part of this service and
              cannot be held liable for any claims or equivalent related to this service.
            </p>
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <Checkbox name="accepted" register={register}>
              I accept and acknowledge.
            </Checkbox>
          </div>
          <div className="w-full px-8 md:px-0 md:w-5/12 mx-auto">
            <Button disabled={!isDirty || !isValid} onClick={() => push('/information')}>Continue</Button>
          </div>
        </div>
      </ContentArea>
    </Background>
  );
};

export default RedirectingPage;
