import classNames from 'classnames';
import React from 'react';
import BackButton from './BackButton';
import CloseButton from './CloseButton';

interface ContentAreaProps {
  light?: boolean;
  isHidden?: boolean;
  title?: string;
  onBack?: () => void;
}

const ContentArea: React.FC<ContentAreaProps> = ({
  children, light, isHidden, title, onBack,
}) => {
  const shouldDisplayStyle = { display: isHidden ? 'none' : 'flex' };
  return (
    <div className="flex flex-col h-full relative z-10 py-5" style={shouldDisplayStyle}>
      <div className="flex justify-between px-5 mb-6 z-10">
        {onBack ? <BackButton light={light} onClick={onBack} /> : <div className="h-5 w-5" />}
        <span className={classNames({ 'text-white': light })}>{title}</span>
        <CloseButton light={light} />
      </div>
      {children}
    </div>
  );
};

export default ContentArea;
