import React from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import Background from '../../components/Background';
import ContentArea from '../../components/ContentArea';
import Button from '../../components/Button';
import { ReactComponent as Earth } from '../../assets/earth.svg';

type ThankYouLocationState = {
  total: number,
  tonnes: number,
};

const ThankYouPage: React.FC = () => {
  const { state } = useLocation<ThankYouLocationState>();
  if (!state) return <Redirect to="/card-info" />;
  const tonneText = state.tonnes > 1 ? 'tonnes' : 'tonne';
  const date = new Date().toLocaleString().replace(/(.*)\D\d+/, '$1');
  return (
    <Background type="cloud">
      <ContentArea>
        <div className="flex-grow px-6 mt-4 text-center mb-6">
          <Earth className="mx-auto" />
          <h1 className="mt-4 font-bold text-2xl text-secondary">Thank you!</h1>
          <p className="mt-3 text-lg font-medium text-secondary-darker">
            {`You have just offset ${state.tonnes} ${tonneText} of CO2.`}
          </p>
          <hr className="md:w-4/12 mx-auto my-8" />
          <p className="font-semibold text-lg">Amount</p>
          <p className="font-semibold text-4xl my-2">
            {`€${state.total}`}
          </p>
          <p className="font-medium text-sm">
            {date}
          </p>
        </div>
        <Link className="w-full px-8 md:px-0 md:w-5/12 mx-auto" to="/certificate">
          <Button color="bg-accent-darker">Get a certificate</Button>
        </Link>
      </ContentArea>
    </Background>
  );
};

export default ThankYouPage;
