/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any  */
import classNames from 'classnames';
import React from 'react';
import {
  ErrorOption, FieldValues, UseFormRegister, UseFormSetValue,
} from 'react-hook-form';
import Select, { components, InputProps as InternalInputProps } from 'react-select';
import InputError from '../InputError';

import './select-style.css';

interface InputProps {
  name: string;
  className?: string;
  error?: ErrorOption;
  hideError?: boolean;
}

interface TextInputProps extends InputProps {
  name: string;
  placeholder: string;
  value?: string;
  register: UseFormRegister<any>;
}

export const TextInput: React.FC<TextInputProps> = ({
  name, placeholder, className, register, error, hideError,
}) => (
  <>
    <input placeholder={placeholder} className={classNames('border w-full rounded-md shadow-sm bg-white p-5', { 'border-red-500': error, 'border-transparent': !error }, className)} {...register(name)} />
    <InputError hideError={hideError} name={name} error={error && error.message} />
  </>
);

interface SelectInputProps extends InputProps{
  error?: ErrorOption;
  options: Record<string, string>,
  placeholder?: string;
  setValue: UseFormSetValue<FieldValues>;
}

const InternalSelectInput = (
  props: InternalInputProps,
) => <components.Input {...props} autoComplete="new-password" />;

export const SelectInput: React.FC<SelectInputProps> = ({
  placeholder, className, name, options, setValue, error, hideError,
}) => (
  <>
    <Select
      components={{ Input: InternalSelectInput }}
      inputProps={{ autoComplete: 'none', autoCorrect: 'none', spellCheck: 'none' }}
      id={`select-${name}`}
      isSearchable
      name={name}
      menuPortalTarget={document.querySelector('body')}
      placeholder={placeholder}
      classNamePrefix="react-select"
      className={classNames('rounded-md shadow-sm border', { 'border-red-500': error, 'border-transparent': !error }, className)}
      options={Object.keys(options).map(
        (optionKey) => ({ value: optionKey, label: options[optionKey] }),
      )}
      onChange={(selected) => setValue(
        name, selected?.value,
        {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        },
      )}
    />
    <InputError hideError={hideError} name={name} error={error && error.message} />
  </>
);
