import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';

const schema = yup.object().shape({
  terms: yup.bool()
    .oneOf([true]),
  gdpr: yup.bool()
    .oneOf([true]),
});

const Form: React.FC = () => {
  const history = useHistory();
  const { register, handleSubmit, formState: { isDirty, isValid } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (): void => history.push('/onboarding');

  return (
    <div className="flex flex-grow items-center">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="md:flex justify-between mx-auto px-8 md:w-1/2">
          <Checkbox name="terms" register={register}>
            I accept the
            <Link to="/terms-and-conditions"> Terms and Conditions</Link>
          </Checkbox>
          <Checkbox name="gdpr" register={register}>
            I accept the
            <Link to="/privacy"> Data handling and Privacy Policy</Link>
          </Checkbox>
        </div>
        <div className="w-full px-8 md:px-0 md:w-5/12 mx-auto">
          <Button disabled={!isDirty || !isValid}>Continue</Button>
        </div>
      </form>
    </div>
  );
};

export default Form;
