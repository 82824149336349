import React, { useContext, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FieldValues } from 'react-hook-form';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import Background from '../../components/Background';
import Button from '../../components/Button';
import ContentArea from '../../components/ContentArea';
import { SelectInput, TextInput } from '../../components/Input';
import countries from './countries';
import { ButtonGroup, ButtonGroupButton } from '../../components/ButtonGroup';
import { CartContext } from '../../context/cart-context';
import BillingDetailsType from '../../types/BillingDetails.type';

yup.setLocale({
  mixed: {
    required: 'This field is required',
  },
  string: {
    email: 'Invalid email format',
  },
});

const personalSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required().email(),
  country: yup.string().required(),
  zip: yup.string().required(),
  city: yup.string().required(),
  street: yup.string().required().min(2, 'This field must contain at least 2 characters'),
});

const companySchema = yup.object().shape({
  companyName: yup.string().required(),
  companyEmail: yup.string().required().email(),
  companyCountry: yup.string().required(),
  companyZip: yup.string().required(),
  companyCity: yup.string().required().min(2, 'This field must contain at least 2 characters'),
  companyStreet: yup.string().required().min(2, 'This field must contain at least 2 characters'),
  companyTaxNumber: yup.string().required(),
});

const BillingInfoPage: React.FC = () => {
  const history = useHistory();
  const { setBillingDetails, billingDetails } = useContext(CartContext);
  const [isPersonal, setIsPersonal] = useState(true);
  const {
    register, handleSubmit, setValue, formState: { isDirty, isValid, errors },
  } = useForm({
    shouldFocusError: false,
    resolver: yupResolver(isPersonal ? personalSchema : companySchema),
    mode: 'all',
    defaultValues: billingDetails as FieldValues,
  });

  const onSubmit = (data: BillingDetailsType): void => {
    setBillingDetails(data);
    history.push('/card-info');
  };

  return (
    <Background type="green">
      <ContentArea light>
        <div className="px-6 mt-4">
          <p className="text-3xl text-white font-bold">
            Add billing details
          </p>
          <p className="text-white font-medium opacity-75">
            Fill in the fields below
          </p>
        </div>
        <div className="bg-white flex-grow -mb-5 md:w-full rounded-t-2xl mt-12 py-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ButtonGroup className="mb-5">
              <ButtonGroupButton
                className="border-r-0 rounded-l-lg"
                onClick={() => setIsPersonal(true)}
                isActive={isPersonal}
              >
                Personal
              </ButtonGroupButton>
              <ButtonGroupButton
                className="border-l-0 rounded-r-lg"
                onClick={() => setIsPersonal(false)}
                isActive={!isPersonal}
              >
                Company
              </ButtonGroupButton>
            </ButtonGroup>
            <div className="flex-grow flex flex-wrap w-full md:w-9/12 mx-auto px-8 mb-5">
              {
                isPersonal ? (
                  <>
                    <div className="w-full md:w-1/2 md:pr-4">
                      <TextInput name="name" placeholder="Name" register={register} error={errors.name} />
                      <SelectInput placeholder="Country" name="country" options={countries} className="mt-4" setValue={setValue} error={errors.country} />
                      <TextInput name="city" placeholder="City" className="mt-4" register={register} error={errors.city} />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-4">
                      <TextInput name="email" placeholder="Email" className="mt-4 md:mt-0" register={register} error={errors.email} />
                      <TextInput name="zip" placeholder="Zip" className="mt-4" register={register} error={errors.zip} />
                      <TextInput name="street" placeholder="Street" className="mt-4" register={register} error={errors.street} />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-full md:w-1/2 md:pr-4">
                      <TextInput name="companyName" placeholder="Company name" register={register} error={errors.companyName} />
                      <SelectInput placeholder="Country" name="companyCountry" options={countries} className="border mt-4" setValue={setValue} error={errors.companyCountry} />
                      <TextInput name="companyCity" placeholder="City" className="mt-4" register={register} error={errors.companyCity} />
                      <TextInput name="companyTaxNumber" placeholder="Tax number" className="mt-4" register={register} error={errors.companyTaxNumber} />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-4">
                      <TextInput name="companyEmail" placeholder="Email" className="mt-4 md:mt-0" register={register} error={errors.companyEmail} />
                      <TextInput name="companyZip" placeholder="Zip" className="mt-4" register={register} error={errors.companyZip} />
                      <TextInput name="companyStreet" placeholder="Street" className="mt-4" register={register} error={errors.companyStreet} />
                    </div>
                  </>
                )
              }
            </div>
            <div className="w-full px-8 md:px-0 md:w-5/12 mx-auto">
              <Button color="bg-accent-darker" disabled={!isDirty || !isValid}>Continue</Button>
            </div>
          </form>
        </div>
      </ContentArea>
    </Background>
  );
};
export default BillingInfoPage;
