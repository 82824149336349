import classNames from 'classnames';
import React from 'react';
import { infoPage as images } from '../../images';

interface BackgroundProps {
  type: 'green' | 'white' | 'cloud',
}
const Background: React.FC<BackgroundProps> = ({ type, children }) => {
  const types = {
    green: 'bg-gradient-to-tr from-accent to-accent-darker',
    white: 'bg-white',
    cloud: 'bg-top bg-clouds',
  };
  return (
    <>
      { type === 'cloud' && <img src={images.clouds} alt="cloud" className="absolute bottom-0 left-0 right-0 w-full z-10" />}
      <div className={classNames('relative h-full overflow-x-hidden md:rounded-lg', types[type])}>
        {children}
      </div>
    </>
  );
};

export default Background;
