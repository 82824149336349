import classNames from 'classnames';
import React from 'react';

interface ButtonProps {
  disabled?: boolean;
  color?: 'bg-primary' | 'bg-accent-darker';
  className?: string;
  onClick?: React.MouseEventHandler;
}

const Button: React.FC<ButtonProps> = ({
  onClick, disabled, color = 'bg-primary', className, children,
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    type="submit"
    className={
    classNames(
      `disabled:bg-gray-500 mt-4 ${color} text-white text-center rounded-4xl w-full py-4 z-10`,
      className,
    )
  }
  >
    {children}
  </button>
);

export default Button;
