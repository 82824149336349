import React, { ReactElement } from 'react';

const PopupWrapper: React.FC = ({ children }): ReactElement => (
  <div className="md:flex md:flex-col md:justify-center md:align-center md:h-screen md:bg-primary md:bg-opacity-60 h-full">
    <div className="relative md:w-6/12 md:rounded-lg md:mx-auto h-full md:h-auto md:overflow-hidden wrapper-size">
      {children}
    </div>
  </div>
);

export default PopupWrapper;
