import React from 'react';
import { CardCvcElement } from '@stripe/react-stripe-js';
import { StripeCardCvcElementChangeEvent } from '@stripe/stripe-js';
import inputOptions from './inputOptions';
import CardElementWrapper from './CardElementWrapper';

interface CardCVCProps {
  onChange: (e: StripeCardCvcElementChangeEvent) => void,
  error?: string;
}

const CardCVC: React.FC<CardCVCProps> = ({ onChange, error }) => (
  <CardElementWrapper inputName="cardCvc" error={error}>
    <CardCvcElement
      id="cardCvc"
      options={{
        placeholder: '123',
        ...inputOptions,
      }}
      onChange={onChange}
    />
  </CardElementWrapper>
);

export default CardCVC;
