import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import Background from '../../components/Background';
import Button from '../../components/Button';
import ContentArea from '../../components/ContentArea';
import { LogoWhite } from '../../components/Logo';
import OffsetInput from './OffsetInput';
import ExactValueButton from './ExactValueButton';
import calculateProductCount from '../../utils/calculateProductCount';
import { CartContext } from '../../context/cart-context';
import CarbonFootprint from './CarbonFootprint';
import { ReactComponent as CarbonMeter } from '../../assets/carbon-meter.svg';

const OffsetPage: React.FC = () => {
  const { products, setProduct } = useContext(CartContext);
  const [offset, setOffsetState] = useState(20);

  useEffect(() => {
    const productCount = calculateProductCount(offset);

    if (productCount < 1) {
      return setProduct(0, 1);
    }
    return setProduct(1, productCount);
  }, [offset]);

  const setOffset = useCallback((value: number) => {
    setOffsetState(value);
  }, []);

  const addOffset = useCallback(() => {
    setOffsetState((offsetVal) => {
      if (offsetVal === 12) return 20;
      return offsetVal + 20;
    });
  }, []);

  const negateOffset = useCallback(() => {
    setOffsetState((offsetVal) => {
      if (offsetVal <= 20) return 12;
      return offsetVal - 20;
    });
  }, []);

  return (
    <Background type="green">
      <ContentArea light>
        <div className="mx-auto px-6 mb-5 w-4/12 md:mb-11 md:w-2/12">
          <LogoWhite />
        </div>
        <div className="px-6 mt-4">
          <CarbonMeter className="mx-auto mb-7" />
          <p className="text-3xl text-white text-center font-bold">
            Reduce your Bitcoin
            carbon footprint now!
          </p>
        </div>
        <div className="bg-white flex-grow -mb-5 md:w-full rounded-t-2xl mt-12 py-5">
          <div className="flex flex-col h-full w-full md:mx-auto">
            <div className="md:w-1/2 mx-auto px-8">
              <p className="text-secondary-darker font-semibold uppercase text-center">Choose amount to offset:</p>
              <div className="flex flex-row justify-around items-center my-5">
                <OffsetInput offset={offset} addOffset={addOffset} negateOffset={negateOffset} />
              </div>
              <div className="flex justify-around w-full my-5">
                <ExactValueButton value={40} isActive={offset === 40} onClick={setOffset} />
                <ExactValueButton value={200} isActive={offset === 200} onClick={setOffset} />
                <ExactValueButton value={400} isActive={offset === 400} onClick={setOffset} />
              </div>
              <p className="flex-grow text-center my-3">
                <CarbonFootprint products={products} />
              </p>
            </div>
            <Link className="w-full px-8 md:px-0 md:w-5/12 mx-auto" to="billing">
              <Button color="bg-accent-darker">Offset now</Button>
            </Link>
          </div>
        </div>
      </ContentArea>
    </Background>
  );
};
export default OffsetPage;
