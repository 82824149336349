import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import Preloader from '../Preloader';
import RedirectingPage from '../../pages/RedirectingPage';
import InformationPage from '../../pages/InformationPage';
import OnboardingPage from '../../pages/OnboardingPage';
import OffsetPage from '../../pages/OffsetPage';
import BillingInfoPage from '../../pages/BillingInfoPage';
import CardInfoPage from '../../pages/CardInfoPage';
import ThankYouPage from '../../pages/ThankYouPage';
import CertificatePage from '../../pages/CertificatePage';
import TermsPage from '../../pages/TermsPage';
import { EmbedContext } from '../../context/embed-context';
import useEmbed from '../../services/embed-handler';
import PopupWrapper from '../PopupWrapper';
import DataHandlingPage from '../../pages/DataHandlingPage';
import Crypto2CashClosePage from '../../pages/Crypto2CashClosePage';

const Embed: React.FC<{ loading: boolean }> = ({ loading }) => {
  const { isOpen } = useContext(EmbedContext);
  const { isEmbedded } = useEmbed();

  return (
    <>
      {((isEmbedded && isOpen) || !isEmbedded) && (
      <PopupWrapper>
        {loading ? (<Preloader />) : (
          <Switch>
            <Route exact path="/" component={RedirectingPage} />
            <Route exact path="/information" component={InformationPage} />
            <Route exact path="/onboarding" component={OnboardingPage} />
            <Route exact path="/offset" component={OffsetPage} />
            <Route exact path="/billing" component={BillingInfoPage} />
            <Route exact path="/card-info" component={CardInfoPage} />
            <Route exact path="/thank-you" component={ThankYouPage} />
            <Route exact path="/certificate" component={CertificatePage} />
            <Route exact path="/terms-and-conditions" component={TermsPage} />
            <Route exact path="/privacy" component={DataHandlingPage} />
            <Route exact path="/crypto2cash/close" component={Crypto2CashClosePage} />
          </Switch>
        )}
      </PopupWrapper>
      )}
    </>
  );
};

export default Embed;
