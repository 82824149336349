/* eslint-disable import/prefer-default-export */
import { CardNumberElement } from '@stripe/react-stripe-js';
import {
  PaymentIntent, PaymentIntentResult, Stripe, StripeElements,
} from '@stripe/stripe-js';
import BillingDetailsType from '../types/BillingDetails.type';
import convertBillingDetails from '../utils/convertBillingDetails';
import { EmbedProfile } from './embed-handler';

type PaymentApiResponse = {
  clientSecret: string
};

export type PaymentHandlerResponse = {
  title: string,
};

const SOMETHING_WENT_WRONG: PaymentHandlerResponse = {
  title: 'Something went wrong!',
};

const PAYMENT_REJECTED: PaymentHandlerResponse = {
  title: 'The credit card was declined!',
};

const PAYMENT_SDK_ERROR: PaymentHandlerResponse = {
  title: 'Could not connect to the payment provider!',
};

export const pay = (
  billingDetails: BillingDetailsType,
  embedProfileId: EmbedProfile | null,
  stripe: Stripe,
  elements: StripeElements,
  priceId: string | null,
  amount: number,
): Promise<PaymentIntent> => new Promise((resolve, reject) => {
  fetch(
    `${process.env.REACT_APP_API_URL}/transaction-start`,
    {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(
        {
          priceId,
          amount,
          embedProfileId,
          taxNumber: billingDetails.companyTaxNumber,
        },
      ), // body data type must match "Content-Type" header
    },
  )
    .then((response) => response.json())
    // eslint-disable-next-line consistent-return
    .then((response: PaymentApiResponse) => {
      const card = elements.getElement(CardNumberElement);

      if (!card) return reject(SOMETHING_WENT_WRONG);
      stripe.confirmCardPayment(response.clientSecret, {
        payment_method: {
          card,
          billing_details: convertBillingDetails(billingDetails),
        },
      }).then((payload: PaymentIntentResult) => {
        if (payload && payload.paymentIntent && payload.paymentIntent.status === 'succeeded') {
          resolve(payload.paymentIntent);
        } else {
          reject(PAYMENT_REJECTED);
        }
      }).catch(() => {
        reject(PAYMENT_SDK_ERROR);
      });
    }).catch(() => {
      reject(SOMETHING_WENT_WRONG);
    });
});
