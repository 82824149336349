import infoSwipe1 from './assets/swiper/info/swiper-img-1.jpg';
import infoSwipe2 from './assets/swiper/info/swiper-img-2.jpg';
import infoSwipe3 from './assets/swiper/info/swiper-img-3.jpg';
import clouds from './assets/clouds.png';
import tonne from './assets/tonne.png';
import tonnes from './assets/tonnes.png';
import zero from './assets/smoke-numbers/0.png';
import one from './assets/smoke-numbers/1.png';
import two from './assets/smoke-numbers/2.png';
import three from './assets/smoke-numbers/3.png';
import four from './assets/smoke-numbers/4.png';
import five from './assets/smoke-numbers/5.png';
import six from './assets/smoke-numbers/6.png';
import seven from './assets/smoke-numbers/7.png';
import eight from './assets/smoke-numbers/8.png';
import nine from './assets/smoke-numbers/9.png';
import dot from './assets/smoke-numbers/dot.png';
import blankCertificateAsset from './assets/cert-blank.jpg';

export const infoPage = {
  clouds,
  infoSwipe1,
  infoSwipe2,
  infoSwipe3,
};

export const summary = {
  dot,
  tonne,
  tonnes,
  zero,
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
};

export const blankCertificate = blankCertificateAsset;
