/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from 'react';
import BillingDetailsType from '../types/BillingDetails.type';
import mapProducts, { PriceWithAmount } from '../utils/mapProducts';

interface CartContextInterface {
  products: number[];
  billingDetails: BillingDetailsType,
  paymentId?: string;
  getActiveProduct: () => Promise<PriceWithAmount>
  setPaymentId: (id: string) => void;
  setProduct: (productNumber: number, quantity: number) => void;
  setBillingDetails: (data: BillingDetailsType) => void;
}

const defaultCartProducts = [0, 1];
const defaultBillingDetails: BillingDetailsType = {} as BillingDetailsType;

export const CartContext = React.createContext<CartContextInterface>({
  products: defaultCartProducts,
  billingDetails: defaultBillingDetails,
  paymentId: undefined,
  getActiveProduct: () => new Promise(() => ({})),
  setPaymentId: (id: string) => {},
  setProduct: (productNumber: number, quantity: number) => {},
  setBillingDetails: (data: BillingDetailsType) => {},
});

export const CartContextProvider: React.FC = ({ children }) => {
  const [paymentId, setPaymentId] = useState<string>();
  const [products, setProducts] = useState(defaultCartProducts);
  const [billingDetails, setBillingDetails] = useState(defaultBillingDetails);

  const setProduct = useCallback(
    (productNumber: number, quantity: number) => setProducts(
      (currentCartState) => {
        const items = new Array(currentCartState.length).fill(0);
        items[productNumber] = quantity;
        return items;
      },
    ), [children],
  );

  const getActiveProduct = (): Promise<PriceWithAmount> => {
    const activeProductIndex = products.findIndex((el) => el !== 0);
    return mapProducts({ [activeProductIndex]: products[activeProductIndex] });
  };

  const values = {
    products,
    billingDetails,
    paymentId,
    getActiveProduct,
    setPaymentId,
    setProduct,
    setBillingDetails,
  };

  return (
    <CartContext.Provider value={values}>
      {children}
    </CartContext.Provider>
  );
};
