import { StripeCardNumberElementChangeEvent, StripeElementChangeEvent } from '@stripe/stripe-js';
import React from 'react';
import CardErrors from '../../types/CardErrors.type';
import CardCompleted from '../../types/CardCompleted.type';
import CardCVC from './CardCvc';
import CardExpiration from './CardExpiration';
import CardNumber from './CardNumber';

interface CardProps {
  setCardCompleted: React.Dispatch<React.SetStateAction<CardCompleted>>
  setCardType: React.Dispatch<React.SetStateAction<StripeCardNumberElementChangeEvent['brand']>>
  setErrors: React.Dispatch<React.SetStateAction<CardErrors>>,
  errors: Partial<CardErrors>,
}

const Card: React.FC<CardProps> = ({
  setCardCompleted, setCardType, setErrors, errors,
}) => {
  const handleChange = (e: StripeElementChangeEvent) => {
    setCardCompleted((completedState) => ({
      ...completedState,
      [e.elementType]: e.complete,
    }));

    setErrors((currentErrors) => ({
      ...currentErrors,
      [e.elementType]: e.empty ? 'This field is required' : e.error?.message,
    }));
  };

  const handleSaveCardType = (e: StripeCardNumberElementChangeEvent) => {
    if (e.error) return;
    setCardType(e.brand);
  };

  return (
    <>
      <div className="flex mx-auto">
        <div className="w-full">
          <CardNumber
            error={errors?.cardNumber}
            onChange={(e) => {
              handleSaveCardType(e);
              handleChange(e);
            }}
          />
        </div>
      </div>
      <div className="flex mx-auto mb-4 flex-col sm:flex-row">
        <div className="w-full sm:mr-4 sm:w-1/2">
          <CardExpiration
            error={errors?.cardExpiry}
            onChange={handleChange}
          />
        </div>
        <div className="w-full sm:w-1/2">
          <CardCVC
            error={errors?.cardCvc}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default Card;
