import React from 'react';

interface SlideProps {
  Image: string | React.FC<React.SVGProps<SVGSVGElement>>,
  title: string,
  current: number;
  total: number;
}

const Slide: React.FC<SlideProps> = ({
  Image, title, current, total, children,
}) => (
  <div className="text-center bg-white rounded-3xl shadow p-7 mx-auto max-w-full md:max-w-xl">
    <div className="absolute -top-2 left-0 right-0">
      <span className="bg-white p-2 rounded-tl-2xl rounded-tr-2xl font-semibold">
        {`${current} of ${total}`}
      </span>
    </div>
    <Image className="w-full md:w-1/2 mx-auto" />
    <p className="font-bold text-xl mb-4">{title}</p>
    <p>
      {children}
    </p>
  </div>
);

export default Slide;
