import React from 'react';
import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';
import TonneDisplay from '../../components/TonneDisplay';
import CardIcon from '../../components/CardIcon';
import SwipeButton from '../../components/SwipeButton';
import Button from '../../components/Button';

interface SummarySectionProps {
  cardType: StripeCardNumberElementChangeEvent['brand']
  setIsCardAdded: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (e: null, shouldPay?: boolean) => Promise<void>;
  total: number;
  tonne: number;
}

const SummarySection: React.FC<SummarySectionProps> = ({
  cardType,
  setIsCardAdded,
  onSubmit,
  total,
  tonne,
}) => (
  <div className="h-full flex flex-col">
    <div className="px-6 flex flex-col flex-grow">
      <div className="flex-grow">
        <TonneDisplay className="mx-auto mb-5 md:w-7/12" value={tonne} />
        <p className="text-center text-sm text-secondary-darker mb-9">On the next page you will be able to download your offsetting certificate with information on the projects you are supporting</p>

        <p className="font-semibold text-secondary-darker mb-3">Payment method</p>
        <div className="flex pb-4 border-b items-center">
          <CardIcon name={cardType} />
          <p className="font-semibold capitalize ml-3">{cardType}</p>
          <button type="button" className="ml-auto text-accent" onClick={() => setIsCardAdded(false)}>Change</button>
        </div>
      </div>
      <div className="w-full md:w-5/12 md:mt-5 mx-auto">
        <p className="text-xl font-bold md:text-center">Offsetting details</p>
        <div className="flex justify-between mt-3">
          <p>Offsetting amount</p>
          <p>
            €
            {total}
          </p>
        </div>
        <div className="flex justify-between mt-2">
          <p>Payment processing fees</p>
          <p className="text-accent">Free</p>
        </div>
        <div className="flex justify-between mt-2">
          <p>Total</p>
          <p className="font-bold text-xl">
            €
            {total}
          </p>
        </div>
        <p className="text-secondary-darker text-center text-xs mt-6">Payment tranfered in seconds</p>
      </div>
    </div>
    <div className="w-full px-8 md:px-0 md:w-5/12 mx-auto">
      <Button
        className="hidden sm:block"
        color="bg-accent-darker"
        onClick={async (): Promise<void> => { await onSubmit(null, true); }}
      >
        Offset now
      </Button>
      <SwipeButton
        label="Offset now"
        onFinish={
          async (): Promise<void> => { await onSubmit(null, true); }
        }
      />
      <p className="text-center hidden md:block text-accent-darker mt-5 cursor-pointer" onClick={() => setIsCardAdded(false)}>{'< Back'}</p>
    </div>
  </div>
);

export default SummarySection;
