import React from 'react';

interface InputErrorProps {
  hideError?: boolean;
  name: string;
  error?: string
}

const InputError: React.FC<InputErrorProps> = ({ hideError, name, error }) => {
  if (hideError) return null;
  return (<p data-testid={`${name}-error`} className="text-red-500 text-sm mt-2 h-3">{error}</p>);
};

export default InputError;
