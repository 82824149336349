import BillingDetailsType from '../types/BillingDetails.type';
import StripeBillingDetailsType from '../types/StripeBillingDetails.type';

export default (
  data: BillingDetailsType,
): StripeBillingDetailsType => {
  if (data.companyTaxNumber) {
    return {
      name: data.companyName,
      email: data.companyEmail,
      address: {
        country: data.companyCountry,
        city: data.companyCity,
        line1: data.companyStreet,
        postal_code: data.companyZip,
      },
    };
  }

  return {
    name: data.name,
    email: data.email,
    address: {
      country: data.country,
      city: data.city,
      line1: data.street,
      postal_code: data.zip,
    },
  };
};
