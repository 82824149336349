/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { useHistory } from 'react-router-dom';
import Background from '../../components/Background';
import ContentArea from '../../components/ContentArea';

const TermsPage: React.FC = () => {
  const { push } = useHistory();
  return (
    <Background type="white">
      <ContentArea onBack={() => push('/information')} title="ImpactScope Terms & Conditions">
        <div className="p-5 overflow-y-scroll">
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'decimal', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt',
              }}
            >
              <h2
                dir="ltr"
                style={{
                  lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: '#ffffff', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  General
                </span>
              </h2>
            </li>
          </ol>
          <p><br /></p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: '#ffffff', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              ImpactScope Holding Ltd, company reg. number: 01-09-385030, with a registered address at 1114 Budapest, Kemenes utca 14. fszt. 2. EU tax ID: HU29262470, hereinafter “ImpactScope”, provides individuals, companies and organisations with opportunities to purchase offsets in carbon sequestration and carbon avoidance projects.
            </span>
          </p>
          <p><br /></p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: '#ffffff', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              These&nbsp;
            </span>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Terms &amp; Conditions and Privacy Policy are published and maintained &nbsp;
            </span>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: '#ffffff', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              by ImpactScope, whose full company data is provided here:
            </span>
          </p>
          <p><br /></p>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '17.450000000000003pt', paddingLeft: '3.25pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  Name: ImpactScope Holding Kft.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '17.450000000000003pt', paddingLeft: '3.25pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  Registered office of the company:&nbsp;
                </span>
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: '#ffffff', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  1114 Budapest, Kemenes utca 14. fszt. 2.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '17.450000000000003pt', paddingLeft: '3.25pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  Mailing address:&nbsp;
                </span>
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: '#ffffff', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  1114 Budapest, Kemenes utca 14. fszt. 2
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '17.450000000000003pt', paddingLeft: '3.25pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  Company registry number of the company:&nbsp;
                </span>
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: '#ffffff', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  01-09-385030
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '17.450000000000003pt', paddingLeft: '3.25pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  Tax number of the company: 29262470-2-43
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '17.450000000000003pt', paddingLeft: '3.25pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  Contact e-mail: info@impactscope.com
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '17.450000000000003pt', paddingLeft: '3.25pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  Phone number: +36 30 920 9445
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '17.450000000000003pt', paddingLeft: '3.25pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  Conciliation Board: Budapesti Békéltető Testület
                </span>
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', marginLeft: '56.7pt', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              1016 Budapest, Krisztina krt. 99. III. em. 310.
            </span>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              <br />
            </span>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              PO Box: 1253 Budapest, Pf.: 10.
            </span>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              <br />
            </span>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              E-mail:&nbsp;
            </span>
            <a href="mailto:bekelteto.testulet@bkik.hu" style={{ textDecoration: 'none' }}>
              <span style={{
                fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
              }}
              >
                bekelteto.testulet@bkik.hu
              </span>
              <span style={{
                fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
              }}
              >
                <br />
              </span>
            </a>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Fax: +36 1 488 21 86
            </span>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              <br />
            </span>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Phone: +36 1 488 21 31
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', marginLeft: '56.7pt', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              The User (as a consumer) has the right to submit a complaint regarding these Terms &amp; Conditions to the Conciliation Board.
            </span>
          </p>
          <p><br /></p>
          <ol start={2} style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'decimal', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: '#ffffff', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  Definitions
                </span>
              </p>
            </li>
          </ol>
          <p><br /></p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: '#ffffff', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Use of the words “we”, “us”, and “our” refer to ImpactScope and use of the words “you” and “your” refer to any individual, company or organisation which interacts with our Service.
            </span>
          </p>
          <p><br /></p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: '#ffffff', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              The word “User” means the person or entity who uses the Service.
            </span>
          </p>
          <p><br /></p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: '#ffffff', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              The words “Service” and “Services” refer to&nbsp;
            </span>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              the services provided by ImpactScope in which we offer you carbon offsets, including all related content, features, end user interfaces, APIs, graphical overlays and components. The Services are described in more detail in Section 4. ImpactScope’s Services, herein.
            </span>
          </p>
          <p><br /></p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              The words “carbon offsets” mean units of verified and certified emissions reductions in selected environmental projects, either as whole units, fractions of units and configurations thereof.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              “Site” or “App” means the ImpactScope application which you are using now or the website www.impactscope.com.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <ol start={3} style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'decimal', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  Terms &amp; Conditions&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              These Terms &amp; Conditions (“T&amp;Cs”) govern your interactions with our Services. ImpactScope provides the Services to you and may be named as the transmitting party or contracting party for invoices, receipts and similar formal correspondence and documentation. By interacting with ImpactScope’s Services, you are entering into a binding contract with ImpactScope as the provider of the Services.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Your agreement with us includes these T&amp;Cs and our Privacy and Data Handling Policy. By clicking on “I accept the Terms and Conditions and Privacy Policy” button, you acknowledge that you have read and understood the terms of the T&amp;Cs and our Privacy and Data Handling Policy and that you agree to be bound, without restrictions or reservations, by all their provisions. The T&amp;Cs and Privacy and Data Handling Policy, and any additional terms that you agree to, are referred to together as the “Agreements”. If you do not agree with (or cannot comply with) the Agreements, then you may not use our Services.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              The Agreements and any modification of them are available on the Site or App.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              These Agreements cover important information about the Services provided to you, including any charges, taxes, and fees. In order to use our Services, you need to have the power to enter into a binding contract with us and not be barred from doing so under any applicable laws.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              By accepting these Agreements the User warrants to (i) be an adult, able to act on their own behalf, or as a representative of a legal entity; (ii) have read, understood the content and conditions of these Agreements, (iii) have read and understood the Service Provider’s Privacy Policy; (iv) use the Service responsibly; (v) hold ImpactScope harmless against all claims that may arise from a third party, including any claim, cost, demand, deficit or loss of any nature or type.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              The User acknowledges that the electronic submissions constitute a contract between you and ImpactScope. This is an electronic contract. The contract to be bound by electronic submissions applies to all records relating to all transactions the User enters into with ImpactScope, including any notices of cancellation, policies, contracts, and applications. In order to use the Site and App you may be required to have certain hardware and software, which are your sole responsibility. ImpactScope is not responsible for typographic errors in these Agreements or on the Site, in descriptions of products and services, or in any accompanying materials.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              ImpactScope shall use reasonable efforts to protect information submitted by the User in connection with the Services, but the User agrees that the User’s submission of such information is at the User’s sole risk, and the User hereby releases ImpactScope from any and all liability to the User for any loss or liability relating to such information in any way.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              ImpactScope does not represent or guarantee that the Services will be free from loss, corruption, attack, viruses, interference, hacking, or other security intrusion, and the User hereby release ImpactScope from any liability relating thereto.
            </span>
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              4. ImpactScope’s Services
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              When using ImpactScope’s Services we grant you limited, exclusive, non-transferable access to the Services. The &nbsp;carbon offsets will always correspond to actual carbon offsets acquired by ImpactScope that will be retired from the relevant underlying registry and made unavailable for all third parties.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              You agree to use the ImpactScope Services, including all features and functionalities associated therewith, in accordance with all applicable laws, rules and regulations, or other restrictions on use of the Services or content therein. You agree not to archive, download, reproduce, distribute, modify, display, perform, publish, license, create derivative works from, offer for sale or re-sale, or use (except as explicitly authorized in these T&amp;Cs) content and information contained on or obtained from or through the ImpactScope Services, unless approved in writing by ImpactScope.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Unless specifically stated by ImpactScope, the origin of the actual carbon offsets may vary from time to time, and may be affected by a variety of factors, such as your location, and/or availability of carbon offsets. ImpactScope reserves the right to unilaterally replace specified carbon offsets with other carbon offsets provided the replacement carbon offsets satisfy similar criteria such as price and quality. ImpactScope warrants that all carbon offsets are certified by Verra (
            </span>
            <a href="https://verra.org/)" style={{ textDecoration: 'none' }}>
              <span style={{
                fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#0000ff', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
              }}
              >
                https://verra.org)
              </span>
            </a>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
&nbsp;or Gold Standard (
            </span>
            <a href="https://www.goldstandard.org" style={{ textDecoration: 'none' }}>
              <span style={{
                fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#0000ff', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
              }}
              >
                https://www.goldstandard.org
              </span>
            </a>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              ) and such documentation is available upon request.&nbsp;
            </span>
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              4. User Rights and Obligations
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              The only relationship between the you and ImpactScope is for the use of the Services.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              You are required to provide to ImpactScope any and all data which is necessary to provide the Services. You are entitled to use the Services and the Site according to these Agreements. The fees which will be paid by you for the Services are displayed during your carbon offsetting selection process.
            </span>
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              5. Third-Party Applications
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              You may encounter third party applications (including, without limitation, websites, widgets, software, or other software utilities) (“Applications”) that interact with the ImpactScope service. Your use of these Applications may be subject to third party terms of use or license terms.
            </span>
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              6. Customer Support
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              To find more information about our Service and its features or if you need assistance you may contact us at support@impactscope.com.
            </span>
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              7. Term and Termination, Survival
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              These T&amp;Cs are governed by Hungarian law and for an indefinite period.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              ImpactScope may terminate these T&amp;Cs or the Services at any time with or without cause, and with or without notice. ImpactScope will have no liability to the User or any third-party because of such termination. Upon termination or expiration of these T&amp;Cs by either party for any reason, ImpactScope will cease providing the Services.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              All sections of these T&amp;Cs, which by their nature should survive termination will survive termination, including, without limitation, warranty disclaimers and limitations of liability.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              If any provision or provisions of these T&amp;Cs shall be held to be invalid, illegal, or unenforceable, the validity, legality and enforceability of the remaining provisions shall remain in full force and effect.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              8. Electronic Communications
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              We will send you information relating to your use of the Service (e.g. payment authorizations, invoices, receipts, confirmation messages, notices) in electronic form only, for example downloadable material from our API.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              9. Warranty and Disclaimer
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              ImpactScope warrants and guarantees that the offsets acquired will not under any circumstance be re-sold or in any other way made available to any third party. We endeavour to provide the best service we can, but you understand and agree that the ImpactScope Service is provided “as is” and “as available”, without express or implied warranty or condition of any kind, and that the use of the Service is at your own risk. To the fullest extent permitted by applicable law, ImpactScope makes no representations and disclaims any warranties or conditions of satisfactory quality, merchantability, fitness for a particular purpose, or non-infringement, except for the retirement of such carbon offsets. Neither ImpactScope nor any owner of content warrants that the ImpactScope Service is free of malware or other harmful components.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              10. Intellectual Property
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              The Site in its entirety (including, without limitation, content, structure, databases, graphics, images, etc.), as well as the logos, and each of its components, are the exclusive property of ImpactScope. Any reproduction or representation in whole or in part is strictly forbidden.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              You may not, directly or indirectly: reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code, object code, or underlying structure, ideas, or algorithms of or included in the know-how or any software, documentation or data related to the ImpactScope Services.
            </span>
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              11. Entire Agreement
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Other than as stated in this section or as explicitly agreed upon in writing between you and ImpactScope, the Agreements constitute all the terms and conditions agreed upon between you and ImpactScope and supersede any prior agreements in relation to the subject matter of these Agreements, whether written or oral.
            </span>
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              12. Severability and Waiver
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Unless as otherwise stated in the Agreements, should any provision of the Agreements be held invalid or unenforceable for any reason or to any extent, such invalidity or enforceability shall not in any manner affect or render invalid or unenforceable the remaining provisions of the Agreements, and the application of that provision shall be enforced to the extent permitted by law. Any failure by ImpactScope or any third party beneficiary to enforce the Agreements or any provision thereof shall not waive ImpactScope’s or the applicable third party beneficiary’s right to do so.
            </span>
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              13. Assignment
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              ImpactScope may assign the T&amp;Cs or any part of them, and ImpactScope may delegate any of its obligations under these T&amp;Cs. You may not assign the Agreements or any part of them, nor transfer or sub-license your rights under the Agreements, to any third party.
            </span>
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              14. Indemnification
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              To the fullest extent permitted by applicable law, you agree to indemnify and hold ImpactScope harmless from and against all damages, losses, and expenses of any kind (including reasonable attorney fees and costs) arising out of: (1) your breach of this Agreement; (2) any activity in which you engage in or through the ImpactScope Service; and (3) your violation of any law or the rights of a third party.
            </span>
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              15. Refunds
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              ImpactScope offers a full refund if requested within 30 days of the purchase of offsets, "the Refund Period". If you decide within the Refund Period that you do not wish for the the offsets that you purchased to be retired on your behalf, you must notify us of this in writing prior to the expiry of the Refund Period by sending an email to support@impactscope.com. &nbsp;All approved refunds will be processed with 14 (fourteen) days.&nbsp;
            </span>
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '18pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              16 Settlement of Disputes
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Both Impactcope and the User shall do everything in their power to resolve any disputes or disagreements that may arise between them regarding these T&amp;Cs, through direct negotiation.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              The laws of Hungary will govern the validity and construction of these T&amp;Cs and any dispute arising out of or relating to these terms, without regard to the principles of conflict of laws.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
&nbsp;
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              In the event of any dispute arising from or in connection with these T&amp;Cs, so especially with its breach, termination, validity or interpretation, the parties agreed not to use the state court procedures and agree to submit the matter to the exclusive and final decision of the Permanent Arbitration Court attached to the Hungarian Chamber of Commerce and Industry (Commercial Arbitration Court Budapest). The Arbitration Court proceeds in accordance with its own Rules of Proceedings (supplemented with the provisions of the Sub-Rules of Expedited Proceedings). The number of arbitrators shall be three and the language to be used in the arbitral proceedings shall be English. The parties agree not to retry the proceedings as regulated in Section IX of Act no. LX of 2017 on Arbitration.&nbsp;
            </span>
          </p>
        </div>
      </ContentArea>
    </Background>
  );
};

export default TermsPage;
