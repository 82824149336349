import classNames from 'classnames';
import React from 'react';
import InputError from '../InputError';

interface CardElementWrapperProps {
  error?: string;
  inputName: string;
}

const CardElementWrapper: React.FC<CardElementWrapperProps> = ({ children, error, inputName }) => (
  <div className={classNames({ 'invalid-field': error })}>
    {children}
    <InputError name={inputName} error={error} />
  </div>
);

export default CardElementWrapper;
