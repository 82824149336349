import classNames from 'classnames';
import React, { useContext } from 'react';
import useEmbed from '../../services/embed-handler';
import { EmbedContext } from '../../context/embed-context';

interface CloseButtonProps {
  light?: boolean;
}

const CloseButton: React.FC<CloseButtonProps> = ({ light }) => {
  const { isCloseVisible } = useEmbed();
  const { close } = useContext(EmbedContext);

  return (
    <>
      {isCloseVisible ? (
        <button type="button" onClick={close} className={classNames({ 'text-white': light })}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      ) : <div className="h-5 w-5" />}
    </>
  );
};

export default CloseButton;
