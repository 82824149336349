import React, {
  FunctionComponent, useEffect, useState,
} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CartContextProvider } from '../../context/cart-context';
import precacheImages from '../../utils/precache-images';
import { infoPage } from '../../images';
import { EmbedContextProvider } from '../../context/embed-context';
import Embed from '../Embed/Embed';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');
const App: FunctionComponent = () => {
  const [loading, setLoading] = useState(true);

  const imageArray = Object.values(infoPage);
  useEffect(() => {
    precacheImages(imageArray, setLoading);
  }, []);

  return (
    <BrowserRouter>
      <CartContextProvider>
        <EmbedContextProvider>
          <Elements stripe={stripePromise}>
            <Embed loading={loading} />
          </Elements>
        </EmbedContextProvider>
      </CartContextProvider>
    </BrowserRouter>
  );
};

export default App;
