import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Background from '../../components/Background';
import ContentArea from '../../components/ContentArea';
import Button from '../../components/Button';
import TonneDisplay from '../../components/TonneDisplay';
import { CartContext } from '../../context/cart-context';
import calculateCarbonFootprint from '../../utils/calculateCarbonFootprint';
import { blankCertificate } from '../../images';
import { getCertificate } from '../../services/certificate-service';
import saveData from '../../utils/saveData';

const CertificatePage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { products, paymentId } = useContext(CartContext);
  const { push } = useHistory();

  const footprint = calculateCarbonFootprint(products);

  if (!paymentId) {
    push('/card-info');
  }

  const handleCertificateDownload = () => {
    if (!paymentId) return;

    setLoading(true);
    getCertificate(paymentId)
      .then((cert) => saveData(cert))
      .finally(() => setLoading(false));
  };

  return (
    <Background type="green">
      <ContentArea light>
        <div className="px-6 mt-4">
          <div className="relative mx-auto w-8/12 md:w-4/12 mb-5">
            <TonneDisplay className="cert-tonne-display" value={footprint.tonne} />
            <img src={blankCertificate} alt="cert" />
          </div>
          <p className="text-3xl text-white text-center font-bold">
            Get your certificate!
          </p>
        </div>
        <div className="bg-white flex-grow -mb-5 md:w-full rounded-t-2xl mt-16 py-5">
          <div className="flex flex-col h-full w-full md:mx-auto">
            <div className="w-full px-8 md:px-0 md:w-5/12 mx-auto">
              <Button color="bg-accent-darker" onClick={handleCertificateDownload} disabled={loading}>
                {
                  loading ? 'Generating certificate..' : 'Download certificate'
                }
              </Button>
            </div>
            <Link to="/crypto2cash/close" className="text-accent-darker text-center mt-3 cursor-pointer">
              Finish
            </Link>
          </div>
        </div>
      </ContentArea>
    </Background>
  );
};

export default CertificatePage;
