import React, { ReactElement } from 'react';
import Carousel from '../../components/Carousel';
import { Logo } from '../../components/Logo';
import Form from './Form';
import Background from '../../components/Background';
import ContentArea from '../../components/ContentArea';
import Slide from './Slide';

import { infoPage as images } from '../../images';

const InformationPage = (): ReactElement => (
  <Background type="cloud">
    <ContentArea>
      <div className="flex-grow mx-auto px-6 pb-5 md:w-9/12">
        <div className="flex justify-center mb-5">
          <Logo />
        </div>
        <p className="font-bold text-center text-xl">
          Planet Earth thanks you for choosing to offset
          the carbon emissions of your crypto trades.
        </p>
        <p className="text-center mt-3 text-secondary">
          You are now interacting with ImpactScope’s offsetting API.
          You may cancel your offsetting journey at any time.
        </p>
      </div>
      <div>
        <Carousel
          slidesPerView={1.3}
          wrapperClassName="info-page"
          slides={[
            <Slide image={images.infoSwipe1} />,
            <Slide image={images.infoSwipe2} />,
            <Slide image={images.infoSwipe3} />,
          ]}
        />
      </div>
      <Form />
    </ContentArea>
  </Background>
);

export default InformationPage;
