import classNames from 'classnames';
import React, { ReactNodeArray } from 'react';
import SwiperCore, { Pagination, A11y, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import './swiper-override.css';

SwiperCore.use([Pagination, A11y, Virtual]);

interface CarouselProps {
  slides: ReactNodeArray;
  slideClass?: string;
  slidesPerView: number;
  slideClassName?: string;
  wrapperClassName?: string;
}

const Carousel: React.FC<CarouselProps> = ({
  slides, wrapperClassName, slideClassName, slidesPerView,
}) => (
  <Swiper
    centeredSlides
    slidesPerView={slidesPerView}
    pagination={{ clickable: true }}
    className={classNames(['pb-10 overflow-visible', wrapperClassName])}
  >
    {slides.map((slide, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <SwiperSlide key={index} className={slideClassName}>
        {slide}
      </SwiperSlide>
    ))}
  </Swiper>
);

export default Carousel;
