import React, { ReactElement } from 'react';
import Background from '../../components/Background';
import ContentArea from '../../components/ContentArea';

const Crypto2CashClosePage = (): ReactElement => (
  <Background type="cloud">
    <ContentArea>
      <div className="flex flex-col flex-grow md:w-9/12 justify-center mx-auto">
        <p className="font-bold text-center text-xl -mt-20">
          The ImpactScope carbon offsetting application session has ended.
        </p>
        <p className="text-center mt-3 text-secondary">
          Please close this tab and navigate back to the Crypto2Cash application.
        </p>
      </div>
    </ContentArea>
  </Background>
);

export default Crypto2CashClosePage;
