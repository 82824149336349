import classNames from 'classnames';
import React, { useState } from 'react';
import './swipe-button.css';

interface SwipeButtonProps {
  onFinish: () => void | Promise<void>;
  label?: string;
  small?: boolean;
}

const SwipeButton: React.FC<SwipeButtonProps> = ({ onFinish, label, small }) => {
  const [inputValue, setInputValue] = useState(0);
  const maxValue = 150;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(parseInt(e.target.value, 10));
  };

  const handleRelease = async () => {
    if (inputValue >= maxValue) {
      await onFinish();
      return;
    }
    setInputValue(0);
  };

  const handleTouchEnd = () => {
    handleRelease();
  };

  const handleMouseUp = () => {
    handleRelease();
  };

  return (
    <div className="relative sm:hidden">
      <input
        type="range"
        min="0"
        max={maxValue}
        value={inputValue}
        className="pullee mt-4"
        onChange={handleChange}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleTouchEnd}
      />
      {label && <p className={classNames({ 'opacity-0': inputValue > 0, small })}>{label}</p>}
    </div>
  );
};

export default SwipeButton;
