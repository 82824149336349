/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { useHistory } from 'react-router-dom';
import Background from '../../components/Background';
import ContentArea from '../../components/ContentArea';

const DataHandlingPage: React.FC = () => {
  const { push } = useHistory();
  return (
    <Background type="white">
      <ContentArea onBack={() => push('/information')} title="ImpactScope Privacy Policy">
        <div className="p-5 overflow-y-scroll">
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Please read our Privacy Policy (“Privacy Policy”) carefully as it describes our collection, use, disclosure, retention, and protection of your personal information. This Privacy Policy applies to any website, application or service which references this Privacy Policy. Where you provide us with your personal information in any of the ways described herein, you agree that we may collect, store and use it:
            </span>
          </p>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  in order to perform our contractual obligations to you;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  based on our legitimate interests for processing (i.e. for internal administrative purposes, data analytics and benchmarking, maintaining automated backup systems or for the detection or prevention of crime); or
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  based on your consent, which you may withdraw at any time, as described in this Privacy Policy.
                </span>
              </p>
            </li>
          </ul>
          <h2 dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '18pt', marginBottom: '6pt' }}>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              1.) The Company
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              This Privacy Policy applies to all products, applications, and services offered by ImpactScope Holding Ltd, company reg. number: 01-09-385030, with a registered address at 1114 Budapest, Kemenes utca 14. fszt. 2. EU tax ID: HU29262470.
            </span>
          </p>
          <h2 dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '18pt', marginBottom: '6pt' }}>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              2.) Collecting Information
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              To the extent permissible under applicable law, we collect information about you when you:
            </span>
          </p>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  use our websites, applications or services; this may include your name (including business name), address and email address;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  place an order using our websites, applications or services;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  interact with us using social media; and
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  contact us offline, for example by telephone, SMS, email, post or personally at any conferences.
                </span>
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '6pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              We will also collect your information where you only partially complete and/or abandon any information entered into our website and/or other online forms and may use this information.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              We also collect information from your devices (including mobile devices) and applications you use to access and use any of our websites, applications or services (for example, we may collect the device type, location information and connection information such as statistics on your page views, traffic to and from the sites, referral URL) and we will ask for your permission before we do so).
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '8pt 0pt 14pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              3.) How We Use Your Information
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '-8pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              To the extent permissible under applicable law, we use your information to:
            </span>
          </p>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  provide any information and services that you have requested or any applications or services that you have ordered;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  provide, maintain, protect and improve any applications, products, services and information that you have requested from us;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  manage and administer your use of applications, products and services you have asked us to provide;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  manage our relationship with you (for example, customer service and support activities);
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  monitor, measure, improve and protect our content, website, applications and services and provide an enhanced, personal, user experience for you;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  provide you with any information that we are required to send you to comply with our regulatory or legal obligations;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  detect, prevent, investigate or remediate, crime, illegal or prohibited activities or to otherwise protect our legal rights (including liaison with regulators and law enforcement agencies for these purposes);
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  to monitor, carry out the statistical analysis and benchmarking, provided that in such circumstances it is on an aggregated basis which will not be linked back to you or any living individual;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  deliver targeted advertising, marketing (including in-product messaging) or information to you which may be useful to you, based on your use of our applications and services;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  deliver joint content and services with third parties with whom you have a separate relationship (for example, social media providers); and
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  provide you with location-based services (for example, advertising and other personalized content), where we collect geo-location data.
                </span>
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '6pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Our website, applications (including mobile applications) and services may contain technology that enables us to:
            </span>
          </p>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  check specific information from your device or systems directly relevant to your use of the websites, applications or services against our records to make sure the websites, applications or services are being used in accordance with our end-user agreements and to troubleshoot any problems;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  obtain information relating to any technical errors or other issues with our website, applications, and services;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  collect information about how you and users use the functions of the features of our website, applications, and services; and
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  gather statistical information about the operating system and environment from which you access our applications or services.
                </span>
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '6pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              We may also use information we gather to deliver targeted advertising, marketing (including in-product messaging) or information to you which may be useful, based on your use of the website, applications or services or any other information we have about you (depending on the websites, applications or services, you may able to configure these features to suit your preferences).
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              We may monitor and record our communications with you, including e-mails and phone conversations. Information which we collect may then be used for training purposes, quality assurance, to record details about our website, applications and services you order from us or ask us about, and in order to meet our legal and regulatory obligations generally.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '8pt 0pt 14pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              4.) Data Analytics and Benchmarking
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '-8pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              We may use information generated and stored during your use of our services for our legitimate business interests to enable us to give you the best service and/or solutions and the best experience. These purposes include to:
            </span>
          </p>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  carry out research and development to improve our services, products, and applications;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  develop and provide new and existing functionality and services (including statistical analysis, benchmarking and forecasting services); and
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  provide you with location-based services (for example location relevant content) where we collect geo-location data to provide a relevant experience.
                </span>
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '6pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              You have the right to object to processing based on our legitimate interests, and if you wish to do so, please contact us at&nbsp;
            </span>
            <a href="mailto:support@impactscope.com" style={{ textDecoration: 'none' }}>
              <span style={{
                fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#0000ff', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
              }}
              >
                support@impactscope.com
              </span>
            </a>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
&nbsp;to discuss this further. If you object, this may affect our ability to provide certain services and/or solutions for your benefit.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '8pt 0pt 14pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              5.) Sharing Your Information
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '-8pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              We may share your information with:
            </span>
          </p>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  our service providers and agents (including their sub-contractors) or third parties which process information on our behalf (e.g. internet service and platform providers, payment processing providers and those organisations we engage to help us send communications to you) so that they may help us to provide you with the applications, products, services and information you have requested;&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  third parties used to facilitate payment transactions for example, clearinghouses, clearing systems, financial institutions and transaction beneficiaries;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  third parties where you have a relationship with that third party and you have consented to us sending information;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  law enforcement agencies so that they may detect or prevent crime or prosecute offenders;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  any third party in the context of actual or threatened legal proceedings, provided we can do so lawfully (for example in response to a court order);
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  any third party in order to meet our legal and regulatory obligations, including statutory or regulatory reporting or the detection or prevention of unlawful acts;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  Government departments where reporting is mandatory under applicable law.
                </span>
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '6pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              We may share non-personally identifiable information about the use of our website, applications, products or services publicly or with third parties but this will not include information that can be used to identify you.
            </span>
          </p>
          <h2 dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '18pt', marginBottom: '6pt' }}>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              6.) Third party platform advertising
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Where you respond to communications we post on third-party platforms (such as Facebook, Google, LinkedIn and Twitter), we may also share your information with those third parties in order to serve targeted advertising/content to you via the relevant third party platform based on your profile/interests. Your information is used by the third-party platform provider to identify your account and serve advertisements to you. You can control what advertisements you receive via the privacy settings on the relevant provider’s platform and you should consult the third party’s help/support center for more information.
            </span>
          </p>
          <h2 dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '18pt', marginBottom: '6pt' }}>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              7.) Your information and your rights
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Based on the applicable rules (Hungarian Privacy Act and GDPR) you have the following rights:
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
&nbsp;
          </p>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  the right to be told how we use your information and obtain access to your information (right of information and access to personal data)&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  the right to have your information rectified or erased (”right to be forgotten”) or place restrictions on processing your information;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  the right to object to the processing of your information e.g. for direct marketing purposes or where the processing is based on our legitimate interests;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  the right to have any information you provided to us on an automated basis returned to you in a structured, commonly used and machine-readable format, or sent directly to another company, where technically feasible (“right to data portability”));
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  where the processing of your information is based on your consent, the right to withdraw that consent subject to legal or contractual restrictions; (“right to restriction of processing”)&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  the right to object to any decisions based on the automated processing of your personal data, including profiling; and
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: 'disc', fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt', paddingLeft: '17.450000000000003pt',
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt',
                }}
              >
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  the right to lodge a complaint with the supervisory authority (Nemzeti Adatvédelmi és Információszabadság Hatóság, H-1125 Budapest, Szilágyi Erzsébet fasor 22/C., mail address: 1530 Budapest, Postafiók: 5., Te: +36 -1-391-1400, Fax: +36-1-391-1410, E-mail:&nbsp;
                </span>
                <a href="mailto:ugyfelszolgalat@naih.hu" style={{ textDecoration: 'none' }}>
                  <span style={{
                    fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                  }}
                  >
                    ugyfelszolgalat@naih.hu
                  </span>
                </a>
                <span style={{
                  fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
                }}
                >
                  ) responsible for data protection matters.
                </span>
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '6pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              If you request a copy of your information you may be required to pay a statutory fee.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              If we hold any information about you which is incorrect or if there are any changes to your details, please let us know by so that we can keep our records accurate and up to date.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              If you withdraw your consent to the use of your personal information for purposes set out in our Privacy Policy, we may not be able to provide you with access to all or parts of our website, applications, and services.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              We will retain your personal information for the duration of our business relationship and afterward for as long as is necessary (maximum 5 years) and relevant for our legitimate business purposes. Where we no longer need your personal information, we will dispose of it in a secure manner (without further notice to you).
            </span>
          </p>
          <h2 dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '18pt', marginBottom: '6pt' }}>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              8.) Changes to this Privacy Policy
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              We may change this Privacy Policy from time to time. However, we will not reduce your rights under this Privacy Policy. We will always update this Privacy Policy on our website. You will always be able to see the latest version at www.imactscope.com/CtoC_Privacy_Policy. The ‘last updated’ reference tells you when we last updated this Privacy Policy).
            </span>
          </p>
          <h2 dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '18pt', marginBottom: '6pt' }}>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              9.) Security and Storage of Information
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              We will keep your information secure by taking appropriate technical and organizational measures against its unauthorized or unlawful processing and against its accidental loss, destruction or damage. We will do our best to protect your personal information but we cannot guarantee the security of your information which is transmitted to our website, applications or services or to other website, applications, and services via an internet or similar connection.&nbsp;
            </span>
          </p>
          <h2 dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '18pt', marginBottom: '6pt' }}>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              10.) Transfers Outside of the European Economic Area
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Personal information in the European Union is protected by data protection laws but other countries do not necessarily protect your personal information in the same way. When you send an email to us, this will also be stored on our email servers which are hosted in the European Union.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              From time to time, we may use service providers based outside of the EEA to help us provide our website, applications,&nbsp;and services to you (for example, platform and payment providers who help us deliver our applications and services, or advertising or execute your payments) and this means that we may transfer your information to service providers outside the EEA for the purpose of providing our applications, advertising,&nbsp;and services to you.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              We take steps to ensure that where your information is transferred outside of the EEA by our service providers and hosting providers, appropriate measures, and controls in place to protect that information in accordance with applicable data protection laws and regulations. For example, we may share information with our group companies or affiliates based outside the EEA for the purposes envisaged by this Privacy Policy. &nbsp;In each case, such transfers are made in accordance with the requirements of Regulations (EU) 2016/679 (the General Data Protection Regulations or “GDPR”) and may be based on the use of the European Commission’s Standard Model Clauses for transfers of personal data outside the EEA.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              By using our website, products or services or by interacting with us in the ways described in this Privacy Policy, you consent to the transfer of your information outside the EEA in the circumstances set out in this Privacy Policy. If you do not want your information to be transferred outside the EEA you should not use our website, applications or services.
            </span>
          </p>
          <h2 dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '18pt', marginBottom: '6pt' }}>
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              11.) Other Sites and Social Media
            </span>
          </h2>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              If you follow a link from our website, application or service to another site or service, this Privacy Policy will no longer apply. We are not responsible for the information handling practices of third party sites or services and we encourage you to read the Privacy Policy appearing on those sites or services.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2', textAlign: 'justify', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 6pt 0pt',
            }}
          >
            <span style={{
              fontSize: '13.999999999999998pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap',
            }}
            >
              Our websites, applications or services may enable you to share information with social media sites, or use social media sites to create your account or to connect your social media account. Those social media sites may automatically provide us with access to certain personal information retained by them about you (for example any content you have viewed). You should be able to manage your privacy settings from within your own third party social media account(s) to manage what personal information you enable us to access from that account.
            </span>
          </p>
          <h2
            dir="ltr"
            style={{
              lineHeight: '1.3800000000000001', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt', padding: '12pt 0pt 6pt 0pt',
            }}
          >
&nbsp;
          </h2>
          <p><br /></p>
          <p><br /></p>
        </div>
      </ContentArea>
    </Background>
  );
};

export default DataHandlingPage;
