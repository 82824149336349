import React from 'react';
import { CardExpiryElement } from '@stripe/react-stripe-js';
import { StripeCardExpiryElementChangeEvent } from '@stripe/stripe-js';
import inputOptions from './inputOptions';
import CardElementWrapper from './CardElementWrapper';

interface CardExpirationProps {
  onChange: (e: StripeCardExpiryElementChangeEvent) => void;
  error?: string;
}

const CardExpiration: React.FC<CardExpirationProps> = ({ onChange, error }) => (
  <CardElementWrapper inputName="cardExpiry" error={error}>
    <CardExpiryElement
      data-test-id="cardExpiry"
      options={{
        placeholder: 'MM / YY',
        ...inputOptions,
      }}
      onChange={onChange}
    />
  </CardElementWrapper>
);

export default CardExpiration;
