import React, { ReactElement } from 'react';

interface SlideProps {
  image: string;
}

const Slide = ({ image }: SlideProps): ReactElement => (
  <img className="rounded-3xl shadow mx-auto max-w-full md:max-w-sm" src={image} alt="swiper" />
);

export default Slide;
