const precacheImages = async (
  srcArray: string[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<void> => {
  setLoading(true);
  const promises = srcArray.map((src) => new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(null);
    img.onerror = () => reject();
  }));

  await Promise.all(promises);
  setTimeout(() => setLoading(false), 800);
};

export default precacheImages;
