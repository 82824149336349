import React from 'react';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';
import inputOptions from './inputOptions';
import CardElementWrapper from './CardElementWrapper';

interface CardNumberProps {
  onChange: (e: StripeCardNumberElementChangeEvent) => void,
  error?: string;
}

const CardNumber: React.FC<CardNumberProps> = ({ onChange, error }) => (
  <CardElementWrapper inputName="cardNumber" error={error}>
    <CardNumberElement data-test-id="cardNumber" options={inputOptions} onChange={onChange} />
  </CardElementWrapper>
);

export default CardNumber;
