import React, {
  MouseEvent, useContext, useEffect, useState,
} from 'react';
import {
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentIntent, StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import Background from '../../components/Background';
import ContentArea from '../../components/ContentArea';
import { pay, PaymentHandlerResponse } from '../../services/payment-handler';
import SummarySection from './SummarySection';
import CardSection from './CardSection';
import { CartContext } from '../../context/cart-context';
import { PriceWithAmount } from '../../utils/mapProducts';
import CardCompleted from '../../types/CardCompleted.type';
import { initCardCompletedState } from './initialState';
import calculateCarbonFootprint from '../../utils/calculateCarbonFootprint';
import Preloader from '../../components/Preloader';
import precacheImages from '../../utils/precache-images';
import { summary } from '../../images';
import PaymentModal from './PaymentModal';
import useEmbed from '../../services/embed-handler';

const CardInfoPage: React.FC = () => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const {
    products, getActiveProduct, billingDetails, setPaymentId,
  } = useContext(CartContext);
  const { embedProfileId } = useEmbed();

  const [cardCompleted, setCardCompleted] = useState<CardCompleted>(initCardCompletedState);
  const [cardType, setCardType] = useState<StripeCardNumberElementChangeEvent['brand']>('unknown');
  const [isCardAdded, setIsCardAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imagesLoading, setImagesLoading] = useState(true);
  const [stripeProduct, setStripeProduct] = useState<PriceWithAmount>();

  const [paymentHandlerResponse, setPaymentHandlerResponse] = useState<PaymentHandlerResponse>();

  if (!Object.values(billingDetails).length) {
    history.push('/billing');
  }

  useEffect(() => {
    const callFetch = async () => {
      const productData = await getActiveProduct();
      return setStripeProduct(productData);
    };
    precacheImages(Object.values(summary), setImagesLoading);
    callFetch();
  }, []);

  if (!stripeProduct || imagesLoading) return <Preloader />;

  const isCardCompleted = !Object.values(cardCompleted).filter((val) => val === false).length;
  const footprint = calculateCarbonFootprint(products);
  const total = (stripeProduct.price.unit_amount / 100) * stripeProduct.amount;

  const initiatePayment = () => {
    if (!stripe || !elements) return;
    setLoading(true);
    pay(
      billingDetails,
      embedProfileId,
      stripe,
      elements,
      stripeProduct.price.id,
      stripeProduct.amount,
    )
      .then((paymentIntent: PaymentIntent) => {
        setLoading(false);
        setPaymentId(paymentIntent.id);
        history.push('/thank-you', {
          total,
          tonnes: footprint.tonne,
        });
      }).catch((err) => {
        setLoading(false);
        // eslint-disable-next-line no-console
        console.error(err);
        setPaymentHandlerResponse(err);
      });
    // }
  };

  const validate = async (e: MouseEvent | null, shouldPay = false) => {
    e?.preventDefault();
    if (isCardCompleted && shouldPay) {
      initiatePayment();
    } else {
      setIsCardAdded(true);
    }
  };

  return (
    <Background type={isCardAdded ? 'white' : 'green'}>
      <PaymentModal
        onClose={() => {
          setIsCardAdded(false);
          setPaymentHandlerResponse(undefined);
        }}
        isVisible={loading || !!paymentHandlerResponse}
        error={!!paymentHandlerResponse}
        message={paymentHandlerResponse?.title}
      />
      <ContentArea light isHidden={isCardAdded} title="Add new card" onBack={() => history.push('/billing')}>
        <CardSection
          disabled={!stripe || !isCardCompleted}
          onSubmit={validate}
          setCardType={setCardType}
          setCardCompleted={setCardCompleted}
        />
      </ContentArea>
      <ContentArea isHidden={!isCardAdded} title="Payment confirmation" onBack={() => setIsCardAdded(false)}>
        <SummarySection
          cardType={cardType}
          setIsCardAdded={setIsCardAdded}
          onSubmit={validate}
          total={total}
          tonne={footprint.tonne}
        />
      </ContentArea>
    </Background>
  );
};
export default CardInfoPage;
