import React from 'react';

export const Logo: React.FC = () => (
  <svg width="117" height="44" viewBox="0 0 117 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.1859 41.9733C27.2757 41.9733 29.7443 39.4042 29.7443 36.3984C29.7443 29.7958 19.3865 32.0823 19.3865 28.7682C19.3865 27.6378 20.3281 26.7129 22.7712 26.7129C24.3491 26.7129 26.0542 27.1754 27.7084 28.1259L28.9808 24.966C27.3266 23.9126 25.0362 23.3731 22.7967 23.3731C17.7323 23.3731 15.2892 25.9165 15.2892 28.9737C15.2892 35.6533 25.647 33.3412 25.647 36.7067C25.647 37.8114 24.6545 38.6335 22.2113 38.6335C20.0736 38.6335 17.8341 37.8627 16.3326 36.758L14.9329 39.8923C16.5107 41.1255 19.361 41.9733 22.1859 41.9733Z" fill="black" />
    <path d="M91.4835 23.6814H83.7724V41.665H87.8951V36.7067H91.4835C96.2425 36.7067 99.22 34.2146 99.22 30.2069C99.22 26.1734 96.2425 23.6814 91.4835 23.6814ZM91.2544 33.3155H87.8951V27.0726H91.2544C93.7739 27.0726 95.0464 28.2287 95.0464 30.2069C95.0464 32.1594 93.7739 33.3155 91.2544 33.3155Z" fill="black" />
    <path d="M107.17 38.3252V34.1633H115.441V30.9262H107.17V27.0212H116.535V23.6814H103.072V41.665H116.866V38.3252H107.17Z" fill="black" />
    <path d="M42.7571 41.9733C45.8619 41.9733 48.4323 40.8429 50.1119 38.7619L47.4652 36.2956C46.2691 37.7086 44.7676 38.4279 42.9861 38.4279C39.6523 38.4279 37.2855 36.0644 37.2855 32.6732C37.2855 29.282 39.6523 26.9185 42.9861 26.9185C44.7676 26.9185 46.2691 27.6378 47.4652 29.0251L50.1119 26.5588C48.4323 24.5035 45.8619 23.3731 42.7825 23.3731C37.2346 23.3731 33.1118 27.2524 33.1118 32.6732C33.1118 38.094 37.2346 41.9733 42.7571 41.9733Z" fill="#51CA89" />
    <path d="M62.0594 41.9733C67.6583 41.9733 71.8065 38.0426 71.8065 32.6732C71.8065 27.3038 67.6583 23.3731 62.0594 23.3731C56.4351 23.3731 52.3124 27.3295 52.3124 32.6732C52.3124 38.0169 56.4351 41.9733 62.0594 41.9733ZM62.0594 38.4279C58.8783 38.4279 56.486 36.0901 56.486 32.6732C56.486 29.2563 58.8783 26.9185 62.0594 26.9185C65.2406 26.9185 67.6328 29.2563 67.6328 32.6732C67.6328 36.0901 65.2406 38.4279 62.0594 38.4279Z" fill="#51CA89" />
    <path d="M77.7916 41.6833L79.1731 40.4905C80.3547 39.4446 80.6274 38.8206 80.6274 38.0866C80.6274 36.6736 79.3549 35.7194 77.3735 35.7194C75.6284 35.7194 74.4832 36.3984 73.9379 37.3709L75.7011 38.3252C75.992 37.8481 76.501 37.5728 77.1554 37.5728C77.8825 37.5728 78.1915 37.8848 78.1915 38.2701C78.1915 38.5637 78.0461 38.8757 77.4099 39.4262L74.3923 42.0503V43.5H80.8819V41.6833H77.7916Z" fill="#51CA89" />
    <path d="M0 17.8778H4.11854V0.301311H0V17.8778Z" fill="black" />
    <path d="M29.3087 17.8778L29.2579 0.301311H25.8766L19.3174 11.2239L12.6566 0.301311H9.24991V17.8778H13.1142V7.5579L18.3259 16.0197H20.1818L25.419 7.33191L25.4444 17.8778H29.3087Z" fill="black" />
    <path d="M42.1421 0.301311H34.4389V17.8778H38.5574V13.0317H42.1421C46.8962 13.0317 49.8707 10.5961 49.8707 6.67907C49.8707 2.73691 46.8962 0.301311 42.1421 0.301311ZM41.9133 9.71729H38.5574V3.61574H41.9133C44.4301 3.61574 45.7013 4.74566 45.7013 6.67907C45.7013 8.58738 44.4301 9.71729 41.9133 9.71729Z" fill="black" />
    <path d="M66.1215 17.8778H70.4434L62.486 0.301311H58.4183L50.4863 17.8778H54.7065L56.2827 14.1114H64.5452L66.1215 17.8778ZM57.6047 11.023L60.4267 4.29369L63.2487 11.023H57.6047Z" fill="black" />
    <path d="M81.4662 18.1791C84.5678 18.1791 87.1355 17.0743 88.8134 15.0405L86.1694 12.63C84.9745 14.011 83.4746 14.714 81.695 14.714C78.3645 14.714 76.0002 12.404 76.0002 9.08956C76.0002 5.77514 78.3645 3.46508 81.695 3.46508C83.4746 3.46508 84.9745 4.16814 86.1694 5.52404L88.8134 3.11355C87.1355 1.10481 84.5678 0 81.4916 0C75.9493 0 71.8308 3.7915 71.8308 9.08956C71.8308 14.3876 75.9493 18.1791 81.4662 18.1791Z" fill="black" />
    <path d="M96.0152 17.8778H100.134V3.61574H105.828V0.301311H90.3204V3.61574H96.0152V17.8778Z" fill="black" />
  </svg>
);

export const LogoWhite: React.FC = () => (
  <svg viewBox="0 0 72 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6685 25.8594C16.8043 25.8594 18.3252 24.2766 18.3252 22.4247C18.3252 18.357 11.9438 19.7656 11.9438 17.7238C11.9438 17.0274 12.5239 16.4576 14.0291 16.4576C15.0012 16.4576 16.0517 16.7425 17.0708 17.3281L17.8548 15.3813C16.8356 14.7324 15.4245 14.4 14.0448 14.4C10.9246 14.4 9.41946 15.967 9.41946 17.8505C9.41946 21.9657 15.8008 20.5412 15.8008 22.6147C15.8008 23.2952 15.1893 23.8017 13.6842 23.8017C12.3671 23.8017 10.9874 23.3269 10.0623 22.6463L9.19995 24.5773C10.1721 25.337 11.9281 25.8594 13.6685 25.8594Z" fill="white" />
    <path d="M56.3621 14.5899H51.6114V25.6694H54.1514V22.6147H56.3621C59.2941 22.6147 61.1285 21.0793 61.1285 18.6102C61.1285 16.1252 59.2941 14.5899 56.3621 14.5899ZM56.221 20.5254H54.1514V16.6792H56.221C57.7732 16.6792 58.5572 17.3915 58.5572 18.6102C58.5572 19.8131 57.7732 20.5254 56.221 20.5254Z" fill="white" />
    <path d="M66.0262 23.6118V21.0477H71.1219V19.0534H66.0262V16.6475H71.7961V14.5899H63.5019V25.6694H71.9999V23.6118H66.0262Z" fill="white" />
    <path d="M26.3423 25.8594C28.2551 25.8594 29.8387 25.1629 30.8735 23.8809L29.2429 22.3614C28.506 23.2319 27.5809 23.6751 26.4834 23.6751C24.4294 23.6751 22.9713 22.219 22.9713 20.1297C22.9713 18.0404 24.4294 16.5842 26.4834 16.5842C27.5809 16.5842 28.506 17.0274 29.2429 17.8821L30.8735 16.3626C29.8387 15.0964 28.2551 14.4 26.3579 14.4C22.9399 14.4 20.3999 16.79 20.3999 20.1297C20.3999 23.4694 22.9399 25.8594 26.3423 25.8594Z" fill="white" />
    <path d="M38.2343 25.8594C41.6837 25.8594 44.2393 23.4377 44.2393 20.1297C44.2393 16.8217 41.6837 14.4 38.2343 14.4C34.7692 14.4 32.2292 16.8375 32.2292 20.1297C32.2292 23.4219 34.7692 25.8594 38.2343 25.8594ZM38.2343 23.6751C36.2744 23.6751 34.8006 22.2348 34.8006 20.1297C34.8006 18.0246 36.2744 16.5842 38.2343 16.5842C40.1941 16.5842 41.668 18.0246 41.668 20.1297C41.668 22.2348 40.1941 23.6751 38.2343 23.6751Z" fill="white" />
    <path d="M47.9267 25.6807L48.7779 24.9459C49.5058 24.3014 49.6738 23.9171 49.6738 23.4648C49.6738 22.5943 48.8899 22.0064 47.6691 22.0064C46.594 22.0064 45.8884 22.4247 45.5525 23.0239L46.6388 23.6118C46.818 23.3179 47.1316 23.1483 47.5347 23.1483C47.9827 23.1483 48.1731 23.3405 48.1731 23.5779C48.1731 23.7588 48.0835 23.951 47.6915 24.2901L45.8324 25.9068V26.8H49.8306V25.6807H47.9267Z" fill="white" />
    <path d="M0 11.0144H2.53739V0.185635H0V11.0144Z" fill="white" />
    <path d="M18.0568 11.0144L18.0255 0.185635H15.9423L11.9013 6.91491L7.79762 0.185635H5.69879V11.0144H8.07955V4.65635L11.2904 9.86961H12.4338L15.6604 4.51713L15.6761 11.0144H18.0568Z" fill="white" />
    <path d="M25.9633 0.185635H21.2175V11.0144H23.7549V8.02873H25.9633C28.8923 8.02873 30.7249 6.52817 30.7249 4.11492C30.7249 1.68619 28.8923 0.185635 25.9633 0.185635ZM25.8224 5.98674H23.7549V2.22762H25.8224C27.373 2.22762 28.1562 2.92376 28.1562 4.11492C28.1562 5.29061 27.373 5.98674 25.8224 5.98674Z" fill="white" />
    <path d="M40.7368 11.0144H43.3995L38.497 0.185635H35.991L31.1041 11.0144H33.7042L34.6753 8.69392H39.7657L40.7368 11.0144ZM35.4898 6.79116L37.2283 2.6453L38.9669 6.79116H35.4898Z" fill="white" />
    <path d="M50.1906 11.2C52.1014 11.2 53.6834 10.5193 54.7171 9.26629L53.0882 7.78121C52.3521 8.63204 51.4279 9.06519 50.3315 9.06519C48.2797 9.06519 46.823 7.64199 46.823 5.6C46.823 3.55801 48.2797 2.13481 50.3315 2.13481C51.4279 2.13481 52.3521 2.56796 53.0882 3.40331L54.7171 1.91823C53.6834 0.680663 52.1014 0 50.2062 0C46.7917 0 44.2543 2.33591 44.2543 5.6C44.2543 8.86408 46.7917 11.2 50.1906 11.2Z" fill="white" />
    <path d="M59.1541 11.0144H61.6915V2.22762H65.2V0.185635H55.6456V2.22762H59.1541V11.0144Z" fill="white" />
  </svg>
);
