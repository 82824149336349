import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';
import React from 'react';

import unknown from '../../assets/cards/unknown.svg';
import mastercard from '../../assets/cards/mastercard.png';
import visa from '../../assets/cards/visa.png';
import amex from '../../assets/cards/amex.png';
import discover from '../../assets/cards/discover.png';
import diners from '../../assets/cards/diners.png';
import jcb from '../../assets/cards/jcb.png';
import unionpay from '../../assets/cards/unionpay.png';

type CardBrand = StripeCardNumberElementChangeEvent['brand'];

interface CardIconProps {
  name: CardBrand;
}

const cardMap: Partial<Record<CardBrand, string>> = {
  unknown,
  mastercard,
  visa,
  amex,
  discover,
  diners,
  jcb,
  unionpay,
};

const CardIcon: React.FC<CardIconProps> = ({ name }) => (
  <img className="rounded border border-secondary-lighter w-16 h-10" src={cardMap[name]} alt={name} />
);

export default CardIcon;
