import classNames from 'classnames';
import React, { useCallback } from 'react';

interface ExactValueButtonProps {
  isActive?: boolean;
  value: number;
  onClick: (value: number) => void
}

const ExactValueButton: React.FC<ExactValueButtonProps> = ({ value, isActive, onClick }) => {
  const className = classNames(
    'rounded-full text-sm font-semibold w-20 h-20',
    {
      'bg-primary text-white shadow-lg': isActive,
      'bg-secondary-lighter text-secondary': !isActive,
    },
  );

  const handleClick = useCallback(() => {
    onClick(value);
  }, []);

  return (
    <button data-testid="exactValueButton" type="button" className={className} onClick={handleClick}>
      €
      {value}
    </button>
  );
};

export default ExactValueButton;
