import React from 'react';
import { ReactComponent as Processing } from '../../../assets/processing.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/error-modal-icon.svg';
import Modal from '../../../components/Modal';

interface PaymentModalProps {
  isVisible?: boolean,
  message?: string,
  error: boolean,
  onClose: () => void;
}

const iconClassName = 'mx-auto mb-6';

const PaymentModal: React.FC<PaymentModalProps> = ({
  isVisible,
  error,
  message = 'We are processing your payment...',
  onClose,
}) => (
  <Modal isVisible={isVisible}>
    {
        error ? <ErrorIcon className={iconClassName} /> : <Processing className={iconClassName} />
    }
    <h1 className="text-center font-bold text-2xl">{message}</h1>
    {
        error && <p onClick={onClose} className="mt-3 text-center text-accent-darker">Back to card details</p>
    }
  </Modal>
);

export default PaymentModal;
