import { fetchPrices } from '../services/stripe-service';
import { PriceWithProduct } from '../types/PriceWithProduct.type';

export interface PriceWithAmount {
  price: PriceWithProduct,
  amount: number,
}

export default async (product: Record<number, number>): Promise<PriceWithAmount> => {
  const productIndex = parseInt(Object.keys(product)[0], 10);

  const priceArray = await fetchPrices();
  const prices = [
    priceArray.find((price) => price.unit_amount === 1200) as PriceWithProduct,
    priceArray.find((price) => price.unit_amount === 2000) as PriceWithProduct,
  ];

  return {
    price: prices[productIndex],
    amount: product[productIndex],
  };
};
