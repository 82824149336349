/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading  */
/* eslint-disable @typescript-eslint/no-explicit-any  */
import React, { ReactElement } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import Tick from './Tick';

interface CheckboxProps {
  name: string;
  register: UseFormRegister<any>;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name, children, register,
}): ReactElement => (
  <label htmlFor={`inp-${name}`} className="flex my-2">
    <div className="mb-2">
      <input data-testid={name} type="checkbox" id={`inp-${name}`} className="opacity-0 absolute h-5 w-5" {...register(name)} />
      <div className="bg-white border-2 rounded-sm border-gray-300 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-accent">
        <Tick />
      </div>
    </div>
    <p className="text-xs">{children}</p>
  </label>
);

export default Checkbox;
